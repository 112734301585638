<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <lparte-trabajo-material-afectado-form
          v-if="articulo && articulo.subfamilia"
          :id="null"
          :idparte-trabajo="parseInt(routeParams.idparte_trabajo)"
          :idtsubfamilia="articulo.subfamilia.idtsubfamilia"
          :idsubsis="idsubsis"
          :idmaterial-sistema="routeParams.idmaterial_sistema"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import Data from './LparteTrabajoMaterialAfectadoAddData'
import LparteTrabajoMaterialAfectadoForm from './components/LparteTrabajoMaterialAfectadoForm'
import { encodeBase64 } from '@/utils/router'

export default {
  components: {
    LparteTrabajoMaterialAfectadoForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      articulo: {},
      materialSistema: {},
    }
  },
  computed: {
    idsubsis () {
      if (this.routeParams.idmaterial_sistema && this.materialSistema && this.materialSistema.material_sistema) {
        return this.materialSistema.material_sistema.idsubsis
      } else if (this.routeQuery.idsubsis) {
        // agregar articulo nuevo desde la lista material afectado de un subsistema
        // subsistema = el subsistema que esta visualizando
        return this.routeQuery.idsubsis
      } else {
        return null
      }
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      // puede añadir material nuevo -> this.routeParams.idarticulo
      // o añadir material de sistema -> this.routeParams.idmaterial_sistema
      if (this.routeParams.idmaterial_sistema) {
        this.materialSistema = await Data.rowMaterialSistema(this, this.routeParams.idmaterial_sistema)
        this.articulo = await Data.rowArticulo(this, this.materialSistema.articulo.idarticulo)
      } else if (this.routeParams.idarticulo) {
        this.articulo = await Data.rowArticulo(this, this.routeParams.idarticulo)
      }
      this.title = `Añadir ${this.articulo.articulo.descripcion}`
    },
    async submitForm () {
      let idsMateriales = []
      if (this.routeParams.idmaterial_sistema) {
        await Data.addMaterialSistemaParte(
          this,
          this.routeParams.idparte_trabajo,
          this.routeParams.idmaterial_sistema,
          this.formData
        )
        idsMateriales.push(this.routeParams.idmaterial_sistema)
      } else {
        idsMateriales = await Data.addMaterialNuevoParte(
          this,
          this.routeParams.idparte_trabajo,
          this.articulo.articulo.idarticulo,
          this.formData.unidades,
          this.formData
        )
      }
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, this.formData.idsubsis_idtsubsis.id)
      this.$appRouter.replace({
        name: 'offline__material-sistema-edit',
        params: {
          idmaterial_sistema: idsMateriales[0],
        },
        query: {
          extra: encodeBase64({ ids: idsMateriales }),
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
  }
}
</script>
